import { DialogService }                from 'aurelia-dialog';
import { bindable, inject }             from 'aurelia-framework';
import { BaseListViewModel }            from 'base-list-view-model';
import { CreateUserEntityTeamLotModal } from 'modules/administration/users/user-entity-team-lot/create';
import { EditUserEntityTeamLotModal }   from 'modules/administration/users/user-entity-team-lot/edit';
import { UserEntityTeamLotsRepository } from 'modules/administration/users/user-entity-team-lot/services/repository';
import { ViewUserEntityTeamLotModal }   from 'modules/administration/users/user-entity-team-lot/view';
import { AppContainer }                 from 'resources/services/app-container';

@inject(AppContainer, UserEntityTeamLotsRepository, DialogService)
export class ListUserEntityTeamLots extends BaseListViewModel {

    listingId = 'management-users-user-entity-team-lots-listing';

    @bindable headerTitle    = null;
    @bindable newRecordRoute = 'administration.users.entity-team-lot.create';
    @bindable repository;
    @bindable datatable;
    @bindable user;

    readonly = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogService
     */
    constructor(appContainer, repository, dialogService) {
        super(appContainer);

        this.repository    = repository;
        this.dialogService = dialogService;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'administration.users.manage',
            'administration.users.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.user     = params.model;
        this.readonly = params.readonly;

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      {
                search:          (criteria) => this.search(criteria),
                destroy:         (id) => this.repository.destroy(id),
                destroySelected: (ids) => this.repository.destroySelected(ids),
            },
            show:            {
                action:  (row) => this.openModal(row, ViewUserEntityTeamLotModal),
                visible: () => this.appContainer.authenticatedUser.can(['administration.users.manage', 'administration.users.view']),
            },
            edit:            {
                action:  (row) => this.openModal(row, EditUserEntityTeamLotModal),
                visible: () => !this.readonly && this.appContainer.authenticatedUser.can(['administration.users.manage', 'administration.users.edit']),
            },
            destroy:         !this.readonly && this.appContainer.authenticatedUser.can(['administration.users.manage', 'administration.users.delete']),
            destroySelected: !this.readonly,
            actions:         [],
            options:         [],
            buttons:         [
                {
                    label:            'form.button.create-new',
                    icon:             'icon-plus3',
                    visible:          !this.readonly && this.appContainer.authenticatedUser.can(['administration.users.manage', 'administration.users.create']),
                    action:           () => this.openModal(this, CreateUserEntityTeamLotModal),
                    outside_dropdown: true,
                    color:            'success',
                },
            ],
            selectable:      true,
            sorting:         [
                {
                    column:    2,
                    direction: 'asc',
                },
                {
                    column:    3,
                    direction: 'asc',
                },
                {
                    column:    4,
                    direction: 'asc',
                },
                {
                    column:    5,
                    direction: 'asc',
                },
                {
                    column:    0,
                    direction: 'asc',
                },
            ],
            columns:         [
                {
                    data:  'entity_name',
                    name:  'entity_translations.name',
                    title: 'form.field.entity',
                },
                {
                    data:  'team_name',
                    name:  'team_translations.name',
                    title: 'form.field.team',
                },
                {
                    data:  'concession_name',
                    name:  'concession_translations.description',
                    title: 'form.field.concession',
                },
                {
                    data:  'highway_name',
                    name:  'highway_translations.designation',
                    title: 'form.field.highway',
                },
                {
                    data:  'intervention_type_name',
                    name:  'intervention_type_translations.designation',
                    title: 'form.field.intervention-type',
                },
                {
                    data:  'lot_intervention_name',
                    name:  'lot_intervention_translations.designation',
                    title: 'form.field.lot',
                },
                {
                    data:            'lot_intervention_start_date',
                    name:            'lot_interventions.start_date',
                    title:           'form.field.start-date',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:            'lot_intervention_finish_date',
                    name:            'lot_interventions.finish_date',
                    title:           'form.field.finish-date',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:       'lot_intervention_closed',
                    name:       'lot_interventions.closed_id',
                    searchable: false,
                    title:      'form.field.finished',
                    type:       'label',
                },
                {
                    data:  'type',
                    name:  'user_entity_team_lot_type_translations.name',
                    title: 'form.field.type',
                },
                {
                    data:  'identifier',
                    name:  'user_entity_team_lots.identifier',
                    title: 'form.field.identifier',
                },
                {
                    data:       'status',
                    name:       'boolean_statuses.name',
                    searchable: false,
                    title:      'form.field.status',
                    type:       'label',
                },
            ],
        };
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.repository.search({ ...criteria, user_id: this.user.id });
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.dialogService
            .open({ viewModel: viewModel, model: model })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.datatable.instance.reload();
                }
            });
    }
}
